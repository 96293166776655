import React, { Fragment, useState, useEffect, Suspense, lazy } from 'react';
import { FaLinkedinIn } from 'react-icons/fa';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import {
    gilroyBold,
    gilroyMedium,
    gilroyRegular,
} from 'shared-components/theme/fonts';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import Media from 'shared-components/theme/medias';
import Button from 'shared-components/button/button';
import MobileMenu from './mobile-menu/mobile-menu';
import BuildYourCXForm from './buildyourCXteam/buildyourCXteam';
import { BlogSubscription } from './blogs/blog-subscription-form';
import {
    TechnologyDropdownMenu,
    IndustriesDropdownMenu,
    SolutionDropdownMenu,
} from 'components/dropdown-menus/dropdown-menus';
import { baseURL } from 'shared-components/theme/helpers';
// import TopBanner from './banners/top-banner'; //it can be used for future banners
const TopBanner = lazy(() => import('./banners/top-banner')); //it can be used for future banners
import fetch from 'cross-fetch';
import { handleMenuTarget } from 'shared-components/theme/helpers';
import { FaAngleDown } from 'react-icons/fa';
import { GrClose } from 'react-icons/gr';
import { HiOutlineSearch } from 'react-icons/hi';
import { gilroySemibold } from 'shared-components/theme/fonts';
import ReactHtmlParser from 'react-html-parser';
import { FlexCentered } from 'shared-components/theme/grid-helpers';
import { baseURLPage } from 'shared-components/theme/helpers';
import Loader from './loader/loader';

const Header = ({
    page,
    onlyLogoHeader,
    handleCXForm,
    searchBlogs,
    noSearchHeader,
    handleCXDemoForm,
    darkTheme,
}) => {
    const [showContactForm, setShowContactForm] = useState(false);
    const [menuData, setMenuData] = useState([]);
    const [vactive, setActive] = useState(false);
    const [blogsSearch, setBlogsSearch] = useState(false);
    const [blogsCategories, setBlogsCategories] = useState([]);
    const [filteredCategory, setFilteredCategory] = useState(null);
    const [searchCategory, setSearchedCategory] = useState();
    const [blogSubform, setBlogSubForm] = useState(false);
    const [hideAllButton, setHideAllButton] = useState(false);
    const [topBanner, setTopBanners] = useState(null);

    const handleScroll = () => {
        const position = window.pageYOffset;
        if (position > 125 && vactive == false) {
            setActive(true);
        } else {
            setActive(false);
        }
    };

    useEffect(() => {
        if (
            handleCXForm &&
            handleCXForm.cxFormState &&
            handleCXForm.handleState !== undefined
        ) {
            setShowContactForm(handleCXForm.cxFormState);
            handleCXForm.handleState(false);
        }
        if (page === 'blogs') {
            getBlogsCategories();
        }
        window.addEventListener('scroll', handleScroll, { passive: true });
        getMenu();
        getTopBanner();
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [handleCXForm]);

    const getMenu = async () => {
        try {
            const res = await fetch(
                process.env.REACT_APP_IBEX_API +
                    '/menu?' +
                    new URLSearchParams({
                        token: process.env.REACT_APP_API_TOKEN,
                        name: 'main_menu',
                    })
            );
            if (res.status >= 400) {
                throw new Error('Bad response from server');
            }
            const resData = await res.json();
            if (resData && resData.status === 200) {
                setMenuData(resData.data);
            }
        } catch (err) {
            console.error(err);
        }
    };
    const getBlogsCategories = async () => {
        try {
            const res = await fetch(
                process.env.REACT_APP_IBEX_API +
                    '/categories?' +
                    new URLSearchParams({
                        token: process.env.REACT_APP_API_TOKEN,
                    })
            );
            if (res.status >= 400) {
                throw new Error('Bad response from server');
            }
            const resData = await res.json();
            if (resData && resData.status === 200) {
                setBlogsCategories(resData.data);
            }
        } catch (err) {
            console.error(err);
        }
    };
    const handleBlogSearch = (event) => {
        if (event.target.value !== '') {
            var categories = [...blogsCategories];
            var filtered = categories.filter((f) =>
                f.name.toLowerCase().includes(event.target.value.toLowerCase())
            );
            setFilteredCategory(filtered);
            setHideAllButton(true);
        } else {
            setFilteredCategory(null);
            setHideAllButton(false);
        }
    };
    const handleCategorySearch = (term_id) => {
        searchBlogs(term_id);
        setBlogsSearch(false);
        setSearchedCategory(term_id);
    };
    const getTopBanner = async () => {
        try {
            const res = await fetch(
                process.env.REACT_APP_IBEX_API +
                    '/glossary?' +
                    new URLSearchParams({
                        token: process.env.REACT_APP_API_TOKEN,
                        post_type: 'configuration',
                    })
            );
            if (res.status >= 400) {
                throw new Error('Bad response from server');
            }
            const resData = await res.json();
            if (resData && resData.status === 200) {
                setTopBanners(resData.data[0]?.metadata);
            }
        } catch (err) {
            console.log('error', err);
        }
    };
    const categories =
        (typeof filteredCategory === 'object' && filteredCategory) ||
        blogsCategories;
    return (
        <Fragment>
            <BlogSubscription
                show={blogSubform}
                closeSubForm={() => setBlogSubForm(false)}
                formId={1309}
            />
            <BuildYourCXForm
                state={showContactForm}
                closeForm={() => setShowContactForm(false)}
                page={page}
            />
            {/* Temporary Comment ,can be re-used for future website top banners */}
            {topBanner &&
                page !== 'events' &&
                page !== 'landingGatedForm' &&
                page !== 'wave-ix' && <TopBanner {...topBanner} />}
            <Wrapper page={page}>
                {page &&
                (page === 'util' ||
                    page === 'wave-ix' ||
                    page === 'event' ||
                    page === 'financial' ||
                    page === 'digital_con' ||
                    page === 'landingGatedForm') ? null : (
                    <FixedSocial>
                        <p>Follow on social</p>
                        <ul>
                            <li>
                                <a
                                    href="https://twitter.com/ibexWaveZero"
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <img
                                        src={`${baseURL}assets/images/twitter-logo.svg`}
                                        alt="-"
                                    />
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://www.linkedin.com/company/ibex-global"
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <FaLinkedinIn />
                                </a>
                            </li>
                        </ul>
                    </FixedSocial>
                )}
                <Container fluid={true}>
                    <Row>
                        <Col xs="6" md="2">
                            <Logo href={baseURLPage}>
                                {/* <img
                                    src={`${baseURL}assets/images/ibex-logo.svg`}
                                    alt="Ibex logo red"
                                    height="30"
                                    width="64"
                                    loading="lazy"
                                /> */}
                                <svg
                                    version="1.1"
                                    id="Layer_1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    x="0px"
                                    y="0px"
                                    viewBox="0 0 79 37"
                                    style={{
                                        enableBackground: 'new 0 0 79 37',
                                    }}
                                    xmlSpace="preserve"
                                    width="64"
                                    height="30">
                                    <style type="text/css">
                                        {`
                                        .st0 { fill: #FA0060; }
                                    `}
                                    </style>
                                    <g>
                                        <g>
                                            <path
                                                className="st0"
                                                d="M0.8,8.1c0-2,1.6-3.6,3.7-3.6S8,6,8,8.1c0,2.1-1.6,3.7-3.6,3.7C2.3,11.7,0.8,10.2,0.8,8.1z M1.3,32.3V12.5 h6.3v19.8L1.3,32.3L1.3,32.3z"
                                            />
                                        </g>
                                        <g>
                                            <path
                                                className="st0"
                                                d="M31,21.9c0,6-4.2,10.4-9.8,10.4c-2.5,0-4.3-1-5.6-2.3v2.3H9.6V4.5h6.3v9.1c1.3-1.2,3-2,5.3-2 C26.8,11.6,31,15.9,31,21.9z M24.6,21.9c0-2.5-1.9-4.6-4.5-4.6c-2.6,0-4.5,2.1-4.5,4.6c0,2.6,1.9,4.6,4.5,4.6 C22.7,26.5,24.6,24.5,24.6,21.9z"
                                            />
                                        </g>
                                        <g>
                                            <path
                                                className="st0"
                                                d="M52.1,23.9H37.9c0.6,1.9,2.2,3.1,4.9,3.1c2.1,0,4.5-0.8,6-1.8l2.5,4.3c-2,1.4-5,2.7-8.8,2.7 c-7.4,0-11-4.9-11-10.4c0-5.9,4.2-10.4,10.6-10.4c5.7,0,10.2,3.6,10.2,9.9C52.3,22.2,52.2,23.1,52.1,23.9z M37.9,19.6h8.4 c-0.5-1.9-2-3.1-4.1-3.1C40.1,16.5,38.5,17.6,37.9,19.6z"
                                            />
                                        </g>
                                        <g>
                                            <path
                                                className="st0"
                                                d="M64.3,32.3l-3.2-6.1l-3.4,6.1h-7.6l6.5-10.5L51,11.5h7.6l2.7,5.6l3.2-5.6h7.3l-6.1,10.3l6.3,10.4L64.3,32.3 L64.3,32.3z"
                                            />
                                        </g>
                                        <circle
                                            className="st0"
                                            cx="74.8"
                                            cy="29.1"
                                            r="3.4"
                                        />
                                    </g>
                                </svg>
                            </Logo>
                        </Col>
                        {onlyLogoHeader &&
                        onlyLogoHeader !== undefined ? null : (
                            <Col xs="6" md="10">
                                <Navigation>
                                    <Menu>
                                        {menuData && menuData.length > 0
                                            ? menuData.map((data, index) => {
                                                  return data.children &&
                                                      data.children.length >
                                                          0 ? (
                                                      <MenuItemDropdown
                                                          key={index}
                                                          page={page}
                                                          industriesMenu={
                                                              data.menu_type ===
                                                                  'type2' &&
                                                              true
                                                          }>
                                                          {handleMenuTarget(
                                                              data
                                                          )}
                                                          <SubMenu
                                                              industriesMenu={
                                                                  data.menu_type ===
                                                                      'type2' &&
                                                                  true
                                                              }>
                                                              {data.menu_type ===
                                                                  'type1' && (
                                                                  <SolutionDropdownMenu
                                                                      subMenu={
                                                                          data.children
                                                                      }
                                                                  />
                                                              )}
                                                              {data.menu_type ===
                                                                  'type2' && (
                                                                  <IndustriesDropdownMenu
                                                                      subMenu={
                                                                          data.children
                                                                      }
                                                                  />
                                                              )}
                                                              {data.menu_type ===
                                                                  'type3' && (
                                                                  <TechnologyDropdownMenu
                                                                      subMenu={
                                                                          data.children
                                                                      }
                                                                  />
                                                              )}
                                                          </SubMenu>
                                                      </MenuItemDropdown>
                                                  ) : (
                                                      <MenuItem
                                                          key={index}
                                                          page={page}>
                                                          {handleMenuTarget(
                                                              data
                                                          )}
                                                      </MenuItem>
                                                  );
                                              })
                                            : null}
                                    </Menu>
                                    {page === 'SocialRepMan' ? (
                                        <BuildCtxBtn page={page}>
                                            <Button
                                                ifButton={true}
                                                round={true}
                                                onClick={() =>
                                                    handleCXDemoForm(true)
                                                }>
                                                Book a Demo
                                            </Button>
                                        </BuildCtxBtn>
                                    ) : (
                                        <BuildCtxBtn page={page}>
                                            <Button
                                                ifButton={false}
                                                round={true}
                                                href={`${baseURLPage}contact-us`}>
                                                Contact Us
                                            </Button>
                                        </BuildCtxBtn>
                                    )}
                                </Navigation>
                                <MobileMenu
                                    data={menuData}
                                    openCxFormPopup={(state) =>
                                        setShowContactForm(state)
                                    }
                                />
                            </Col>
                        )}
                    </Row>
                </Container>
            </Wrapper>

            {page === 'home' && (
                <NoticeBanner>
                    <NoticeLink
                        href={`${baseURL}assets/docs/Ibex - Website Notice.pdf`}
                        target="_blank"
                        rel="noopener noreferrer">
                        Notice of Data Event
                    </NoticeLink>
                </NoticeBanner>
            )}

            {page === 'event' ||
            page === 'digital_con' ||
            page === 'landingGatedForm' ||
            page === 'wavex' ||
            page === 'do-not-call' ||
            page === 'virtual-agent' ? null : (
                <ScrollDown hide={vactive} darkTheme={darkTheme}>
                    <img
                        src={`${baseURL}assets/images/scroll-down-icon.svg`}
                        alt="Scroll down black icon"
                        width="24"
                    />
                    Scroll
                    <br />
                    Down
                </ScrollDown>
            )}
            {page === 'blogs' && !noSearchHeader && (
                <BlogsSearch>
                    <Container>
                        <BlogHeaderRow>
                            <Col lg="6" xs="0" md="5">
                                <h4>The latest in innovative CX.</h4>
                            </Col>
                            <BlogHeadActions lg="6" xs="12" md="7">
                                <BlogHeadButton
                                    subscribeBtn
                                    onClick={() => setBlogSubForm(true)}>
                                    Subscribe
                                </BlogHeadButton>
                                <SearchWrap showSearchPanel={blogsSearch}>
                                    <BlogHeadButton
                                        onClick={() =>
                                            setBlogsSearch(!blogsSearch)
                                        }>
                                        Search <FaAngleDown />
                                    </BlogHeadButton>
                                    <BlogsSearchTags>
                                        <PanelHeader>
                                            <PanelHeading>
                                                what are you looking <br />
                                                for today?
                                            </PanelHeading>
                                            <GrClose
                                                onClick={() =>
                                                    setBlogsSearch(false)
                                                }
                                            />
                                        </PanelHeader>
                                        <PanelSearchBar>
                                            <Label>
                                                <HiOutlineSearch />
                                            </Label>
                                            <Input
                                                type="text"
                                                placeholder="enter your keyword"
                                                onChange={handleBlogSearch}
                                            />
                                        </PanelSearchBar>
                                        <SearchTagWrapper>
                                            <SearchTag
                                                hide={hideAllButton}
                                                onClick={() =>
                                                    handleCategorySearch('all')
                                                }>
                                                All
                                            </SearchTag>
                                            {categories &&
                                                categories.length > 0 &&
                                                categories.map(
                                                    (category, index) => {
                                                        return (
                                                            <SearchTag
                                                                key={index}
                                                                active={
                                                                    (searchCategory ===
                                                                        category.term_id &&
                                                                        true) ||
                                                                    false
                                                                }
                                                                onClick={() =>
                                                                    handleCategorySearch(
                                                                        category.term_id
                                                                    )
                                                                }>
                                                                {ReactHtmlParser(
                                                                    category.name &&
                                                                        category.name
                                                                )}
                                                            </SearchTag>
                                                        );
                                                    }
                                                )}
                                        </SearchTagWrapper>
                                    </BlogsSearchTags>
                                </SearchWrap>
                            </BlogHeadActions>
                        </BlogHeaderRow>
                    </Container>
                </BlogsSearch>
            )}
        </Fragment>
    );
};

const themeColor = (page) => {
    switch (page) {
        case 'digital_con':
            return colors.white['100'];
        case 'financial':
            return colors.financial['dark'];
        default:
            return colors.pink['100'];
    }
};
const NoticeBanner = styled(FlexCentered)`
    display: none;
    padding: 10px;
    border-bottom: 1px solid ${colors.gray_dark['10']};
    ${Media('xlscreens')} {
        padding: 0.73vw;
    }
`;

const NoticeLink = styled.a`
    color: ${colors.gray_dark['100']};
    text-decoration: underline;
    font-size: 16px;
    &:hover {
        color: ${colors.pink['100']};
    }
    ${Media('xlscreens')} {
        font-size: 0.85vw;
    }
`;
const BlogsSearch = styled.div`
    height: 70px;
    position: sticky;
    top: 100px;
    left: 0px;
    background-color: ${colors.white['100']};
    z-index: 9;
    display: flex;
    align-items: center;
    h4 {
        font-size: 16px;
        margin: 0px;
        font-family: ${gilroyRegular};
        font-weight: 400;
    }
    ${Media('xlscreens')} {
        height: 3.64vw;
        top: 5.2vw;
        padding: 0.5vw 0vw 0;
        h4 {
            font-size: 0.83vw;
        }
    }
    ${Media('laptops')} {
        padding: 0px;
    }
    ${Media('tablet')} {
        position: fixed;
        top: 82px;
        width: 100%;
    }
    ${Media('mobile')} {
        position: absolute;
        h4 {
            display: none;
        }
    }
`;
const BlogHeaderRow = styled(Row)`
    align-items: center;
`;
const BlogsSearchTags = styled.div`
    width: 520px;
    height: auto;
    min-height: 250px;
    position: absolute;
    top: 50px;
    background: ${colors.white['100']};
    box-shadow: -1px 1px 9px ${colors.black['20']};
    z-index: 9999;
    right: 6px;
    padding: 40px 35px;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s ease;
    ${Media('xlscreens')} {
        width: 27vw;
        min-height: 13.02vw;
        top: 2.6vw;
        right: 0.31vw;
        padding: 2.08vw 1.82vw;
    }
    ${Media('tablet')} {
        right: 0px;
        padding: 20px;
        width: 100%;
    }
`;
const BlogHeadActions = styled(Col)`
    display: flex;
    justify-content: flex-end;
`;
const Label = styled.label`
    padding: 0px 20px;
    position: relative;
    top: 5px;
    svg {
        font-size: 20px;
        opacity: 0.4;
    }
    ${Media('xlscreens')} {
        padding: 0 1.04vw;
        top: 0.2vw;
        svg {
            font-size: 1.04vw;
        }
    }
`;
const Input = styled.input`
    border: none;
    font-size: 20px;
    color: ${colors.gray['100']};
    font-family: ${gilroyRegular};
    flex: 1;
    &::placeholder {
        color: ${colors.gray['50']};
        font-family: ${gilroyRegular};
    }
    ${Media('xlscreens')} {
        font-size: 1.04vw;
    }
    ${Media('mobile')} {
        font-size: 16px;
    }
`;
const PanelSearchBar = styled.div`
    margin: 20px 0px;
    border-bottom: 1px solid ${colors.black['100']};
    padding-bottom: 15px;
    display: flex;
    padding-right: 30px;
    &:hover {
        border-bottom-color: ${colors.pink['100']};
    }
    ${Media('xlscreens')} {
        margin: 1.04vw 0;
        padding-bottom: 0.78vw;
        padding-right: 1.56vw;
    }
    ${Media('mobile')} {
        padding-right: 0px;
    }
`;
const SearchTagWrapper = styled.div``;
const SearchTag = styled.a`
    padding: 10px 20px;
    border-radius: 50px;
    margin: 10px 5px;
    background-color: ${(props) =>
        props.active ? colors.pink['100'] : colors.gray_lightest['50']};
    display: ${(props) => (props.hide ? 'none' : 'inline-block')};
    cursor: pointer;
    transition: all 0.3s ease-in;
    font-size: 12px;
    color: ${(props) =>
        props.active ? colors.white['100'] : colors.gray['100']};
    font-family: ${gilroySemibold};
    &:hover {
        background-color: ${colors.pink['100']};
        color: ${colors.white['100']};
    }
    ${Media('xlscreens')} {
        padding: 0.52vw 1.04vw;
        border-radius: 2.6vw;
        margin: 0.52vw 0.26vw;
        font-size: 0.62vw;
    }
`;
const BlogHeadButton = styled.button`
    padding: 12px 18px;
    font-size: 14px;
    border-radius: 50px;
    background-color: ${colors.gray_lightest['50']};
    color: ${colors.gray['100']};
    cursor: pointer;
    transition: all 0.3s ease-in;
    position: relative;
    align-items: center;
    display: flex;
    border: none;
    margin-left: 10px;
    svg {
        margin-left: 5px;
        font-size: 16px;
        transition: transform 0.3s ease;
    }
    &:hover {
        background-color: ${colors.pink['100']};
        color: ${colors.white['100']};
    }
    ${Media('xlscreens')} {
        padding: 0.62vw 0.93vw;
        font-size: 0.72vw;
        border-radius: 2.6vw;
        margin-left: 0.52vw;
        svg {
            margin-left: 0.26vw;
            font-size: 0.83vw;
        }
    }
    ${Media('tablet')} {
        z-index: ${(props) => props.subscribeBtn && '999'};
        right: ${(props) => (props.subscribeBtn ? '105px' : '0px')};
    }
`;
const PanelHeader = styled.div`
    display: flex;
    justify-content: space-between;
    svg {
        font-size: 24px;
        cursor: pointer;
        &:hover {
            opacity: 0.6;
        }
    }
    ${Media('xlscreens')} {
        svg {
            font-size: 1.25vw;
        }
    }
`;
const PanelHeading = styled.h3`
    font-size: 24px;
    color: ${colors.black['100']};
    margin: 0;
    text-align: left;
    ${Media('xlscreens')} {
        font-size: 1.25vw;
    }
`;
const SearchWrap = styled.div`
    float: right;
    display: flex;
    align-items: center;
    position: relative;
    ${(props) => {
        if (props.showSearchPanel) {
            return `
            ${BlogsSearchTags}{
                opacity:1;
                visibility:visible;
            }
            ${BlogHeadButton}{
                background-color:${colors.pink['100']};
                color:${colors.white['100']};
                svg{
                    transform:rotate(180deg);
                }
            }
            `;
        }
    }}
    ${Media('tablet')} {
        position: absolute;
        right: 0px;
        width: 100%;
        justify-content: flex-end;
    }
`;

const Wrapper = styled.header`
    padding: 0px 60px;
    position: sticky;
    left: 0;
    top: 0;
    width: 100%;
    background-color: ${(props) =>
        props.page === 'digital_con'
            ? colors.event['dark']
            : colors.gray_lighter['100']};
    z-index: 9999;
    border-bottom: 1px solid
        ${(props) =>
            props.page === 'digital_con'
                ? colors.white['10']
                : colors.gray_dark['10']};
    height: 82px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    ${Media('xlscreens')} {
        height: 5.3vw;
        padding: 0px 5vw;
    }
    ${Media('desktopscreens')} {
        height: 5.8vw;
        padding: 0px 5vw;
    }
    ${Media('tablet')} {
        position: fixed;
        padding: 0 15px;
        padding-right: 0px;
    }
`;

const FixedSocial = styled.div`
    position: fixed;
    width: 175px;
    height: 50px;
    top: calc(50% - 75px);
    right: -62px;
    transform: rotate(90deg);
    transform-origin: 50% 48%;
    display: flex;
    align-items: center;
    justify-content: center;
    ${Media('large')} {
        width: 225px;
        height: 70px;
        right: -79px;
        top: calc(50% - 115px);
    }
    &:before,
    &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0px;
        right: 0px;
    }
    &:before {
        background-color: ${colors.gray_lighter['100']};
        z-index: -2;
    }
    &:after {
        background-color: ${colors.pink['10']};
        z-index: -1;
    }
    p {
        margin: 0px;
        font-size: 11px;
        font-family: ${gilroyMedium};
        ${Media('large')} {
            font-size: 14px;
        }
    }
    ul {
        display: flex;
        margin: 0px;
        padding: 0px 0 0 15px;
        li {
            margin: 0px 5px;
            a {
                display: inline-flex;
                font-size: 12px;
                transition-duration: 0.3s;
                img {
                    height: 17px;
                }
                ${Media('xlscreens')} {
                    img {
                        height: 0.88vw;
                    }
                }
                ${Media('large')} {
                    font-size: 16px;
                }
            }
        }
    }
    ${Media('mobile')} {
        display: none;
    }
`;

const ScrollDown = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    top: 80%;
    z-index: 10;
    font-family: ${gilroyMedium};
    opacity: ${(props) => (props.hide ? '0' : '1')};
    visibility: ${(props) => (props.hide ? 'hidden' : 'visible')};
    visibility: visible;
    transition-duration: 0.3s;
    img {
        margin-bottom: 10px;
    }
    ${Media('xlscreens')} {
        left: 4.6vw;
        font-size: 0.85vw;
        img {
            width: 1.2vw;
        }
    }
    ${Media('tablet')} {
        display: none;
    }
    ${(props) => {
        if (props.darkTheme) {
            return `
                color:${colors.white['100']};
                img{
                    filter:invert(100%);
                }
            `;
        }
    }}
`;

const Logo = styled.a`
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    z-index: 1000;
    img,
    svg {
        width: auto;
        height: 30px;
    }
    ${Media('xlscreens')} {
        padding-left: 3vw;
        img,
        svg {
            height: 1.8vw;
        }
    }
`;

const Navigation = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    ${Media('mobile')} {
        display: none;
    }
`;

const Menu = styled.div`
    margin-bottom: 0;
    display: flex;
    ${Media('tablet')} {
        padding: 0;
        width: 100%;
        margin-top: 50px;
        display: none;
    }
`;

const SubMenu = styled.div`
    position: absolute;
    top: 82px;
    left: 0px;
    display: flex;
    opacity: 0;
    visibility: hidden;
    transition: all 0.4s ease;
    max-height: 0;
    ${(props) => {
        if (props.industriesMenu) {
            return `
                width:800px;
                flex-wrap:wrap;
            `;
        }
    }}
    ${Media('xlscreens')} {
        top: 5.3vw;
        ${(props) => {
            if (props.industriesMenu) {
                return `
                        width:41.66vw;
                    `;
            }
        }}
    }
    ${Media('desktopscreens')} {
        top: 4.1vw;
    }
`;

const MenuItem = styled.div`
    display: flex;
    padding: 10px;
    height: 82px;
    position: relative;
    &:after {
        content: '';
        position: absolute;
        width: 0;
        height: 5px;
        background-color: ${(props) => themeColor(props.page)};
        left: 0px;
        bottom: 0px;
        transition: all 0.5s ease;
    }
    a {
        display: inline-flex;
        align-items: center;
        padding: 0 0px;
        position: relative;
        color: ${(props) =>
            props.page === 'digital_con'
                ? colors.white['100']
                : colors.gray_dark['100']};
        font-family: ${gilroyMedium};
        font-size: 14px;
        sup {
            top: -5px;
        }
        &:hover {
            color: ${(props) => themeColor(props.page)};
        }
    }
    ${Media('xlscreens')} {
        height: 5.3vw;
        padding-left: 0.5vw;
        padding-right: 0.5vw;
        margin: 0px 1vw;
        a {
            font-size: 0.8vw;
            sup {
                font-size: 0.5vw;
            }
        }
    }
    ${Media('desktopscreens')} {
        &:after {
            bottom: -1.1vw;
            height: 3px;
        }
        a {
            font-size: 1.1vw;
        }
    }
    &:hover {
        &:after {
            width: calc(100% - 0px);
        }
        a {
            color: ${(props) => themeColor(props.page)};
        }
    }
`;

const MenuItemDropdown = styled(MenuItem)`
    position: relative;
    &:before {
        content: '';
        position: absolute;
        border-top: 9px solid ${(props) => themeColor(props.page)};
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        right: 0;
        top: 50%;
        transform: translateY(-40%);
    }
    &:nth-child(3) {
        div {
            display: none;
        }
        &:before {
            display: none;
        }
        ${Media('xlscreens')} {
            padding-left: 0.5vw;
            padding-right: 0.5vw;
            margin-right: 0.5vw;
        }
    }

    ${Media('xlscreens')} {
        &:before {
            border-top-width: 0.47vw;
            border-left-width: 0.31vw;
            border-right-width: 0.31vw;
        }
        font-size: 0.9vw;
        padding-right: 0.9vw;
    }
    &:hover {
        ${SubMenu} {
            opacity: 1;
            visibility: visible;
            transition: all 0.4s ease;
            max-height: 600px;
            ${Media('xlscreens')} {
                max-height: 30vw;
            }
        }
    }
`;

const BuildCtxBtn = styled.div`
    margin-left: 20px;
    button {
        background-color: ${(props) => themeColor(props.page)};

        &:hover {
            color: ${(props) => themeColor(props.page)};
        }
    }
    a {
        font-family: ${gilroyBold};
    }
    ${Media('xlscreens')} {
        margin-left: 1.04vw;
    }
    ${Media('tablet')} {
        display: none;
    }
`;

const TopBannerWrapper = styled.div`
    // height: 120px;

    // ${Media('tablet')} {
    //     height: 130px;
    // }
    // ${Media('mobile')} {
    //     height: 176px;
    // }
`;

export default Header;
